<template>
  <div id="InviteList">
   <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      class="InfoMain"
    >
      <div class="personalInfo">
        <div v-for="(i,item) in getDocCate" :key="item" class="list" >
          <div >
            <img v-if="i.user" :src="i.user.headimgurl ? Global.changeImg(i.user.headimgurl) :'../../assets/image/home/image1.png'"/>
            <div >
              <p  class="first">{{i.user.name}}</p>
              <p  class="second">
                <span >付款：</span>
                <span  >¥{{i.total_fee}}</span>
                <span  >返现：</span>
                <span  >¥{{i.reward}}</span>
              </p>
              <p  class="third">{{Global.fromatTimestamps(i.updated_at)}}</p>
            </div>
          </div>
          <span v-if="i.status==1" class="agree">已付款</span>
          <span v-if="i.status==0" class="noAgree">未付款</span>
        </div>
      </div>
   </van-list>

  </div>
</template>
<script>
export default {
  name: 'InviteList',
   data(){
    return{
      finished:false,
      loading:true,
      next_page_url:'',
      getDocCate:[],//文件分类
    }
  },
  mounted(){
    this.axios({
      method:'GET',
      url:'/user/inviteRecord',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res)=>{
       if(res.data.code == 0){
        this.loading = false
        this.getDocCate = res.data.data.data;
        this.next_page_url = res.data.data.next_page_url
      }
    })
  },
  methods:{
     onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url: this.next_page_url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocCate.push(...res.data.data.data)
              this.next_page_url = res.data.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
  }
}
</script>
<style lang="less" scoped>
#InviteList{
   background: #23252E;
  .personalInfo{
    border-top: 1px solid #5C5D6E61;
    .list{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: .1px solid #5C5D6E61;
      margin: 0 0.15rem;
      padding: 0.13rem 0;
      >div:first-child{
        display: flex;
        align-items: center;
        flex: 1;
        padding-right: 0.1rem;
        >img{
          width: 0.6rem;
          height: 0.6rem;
          border-radius: 50%;
        }
        >div{
          padding: 0 0 0 0.15rem;
          position: relative;
          flex:1;
          .first{
            font-size: 0.16rem;
            color: #F6F7FD;
          }
          .third{
            display: flex;
            flex-wrap: wrap;
            color: #9091A3;
            font-size: 0.12rem;
            margin: 0 0.05rem 0.05rem 0;
          }
          .second{
            margin: 0.03rem 0 0.07rem 0;
            display: flex;
            align-items: center;
            >span{
              font-size: 0.14rem;
              display: flex;
              color: #989AB1;
            }
            >span:nth-child(2){
              margin-right: 0.1rem;
            }
            >span:nth-child(4){
              color: #ff9c21;
            }
          }
        }
      }
      >span{
        color: #727387;
        font-size: 0.14rem;
      }
      .agree{
        color: #3890FF;
      }
      .noAgree{
        color: #E55959;
      }
      >div:last-child{
        display: flex;
        >span{
          height: 0.25rem;
          border-radius: 0.25rem;
          padding: 0 0.12rem;
          line-height: 0.25rem;
          font-size: 0.12rem;
          color: #F6F7FD;
        }
        .red{
          margin-left: 0.11rem; 
          background: #E55959;
        }
        .blue{
          background: #3890FF;
        }
      }
    }
  }
}
</style>